﻿import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService, AuthenticationService } from '@finesoft/core/services';
import { StyleService } from '@finesoft/core/services/style.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Val } from '@finesoft/core/models/login'
import { PARAMETERS } from '@angular/core/src/util/decorators';

/**
 *
 *
 * @export
 * @class LoginComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  moduleId: module.id.toString(),
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  /**
   *
   *
   * @type {FormGroup}
   * @memberof LoginComponent
   */
  form:        FormGroup;
  bgimage:     string;        // background image
  logoImage:   string;        // logo image
  homepage:    any;           // link for the user's homepage
  private sub: any;           // alternative login features via url parameters
  private language: string = "fi";

  lang = {
    fi: {
      userName: 'Käyttäjänimi',
      password: 'Salasana',
      login: 'Kirjaudu',
      forgot: 'Unohdin salasanani',
    },
    en: {
      userName: 'Username',
      password: 'Password',
      login: 'Login',
      forgot: 'I forgot my password',
    }
  }

  // types of validateros
  formErrors = {
    username: '',
    password: ''
  };

  // TODO: get validation messages from language file
  validationMessages = {
    fi: {
      username: {
        required: 'Käyttäjänimi vaaditaan',
        minlength: 'Käyttäjänimen täytyy olla vähintään kolme merkkiä'
      },
      password: {
        required: 'Salasana vaaditaan',
        minlength: 'Salasanan täytyy olla vähintää neljä merkkiä'
      }
    },
   
    en: {
      username: {
        required: 'Username is required',
        minlength: 'Username must be atleast 3 characters long'
      },
      password: {
        required: 'Password is required',
        minlength: 'Password must be atleast 4 characters long'
      }
    }
  };

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private styleService: StyleService,
    private spinner: NgxSpinnerService) { }

  /**
   *
   *
   * @memberof LoginComponent
   */
  ngOnInit():void {
    // at first look login parameters
    this.sub = this.route.params.subscribe(params => {
      // get style parameters
      let temp: string = ('null' !== params['styleId']) ? params['styleId'] : '';
      if (typeof(temp) === 'undefined' || 'undefined' === temp) {
        temp = '';
      }
      

      // let hostname = window.location.hostname;
      // if (hostname.includes('yritykset.howdenfinland')) temp = 'insurance';
      // if (hostname.includes('nopest.storageit')) temp = 'nopest';
      // if (hostname.includes('dispelix.storageit')) temp = 'dispelix';
      temp = this.styleService.getStyleID(temp)

      // get language
      this.language = this.styleService.getLanguage(temp);
      // get logo
      this.logoImage = this.styleService.getLoginLogo(temp);
      // get customer's link
      this.homepage = this.styleService.getCustomerLink(temp);
      // set background
      this.styleService.setLoginBackground(temp);
      // get favicon
      this.styleService.setFavicon(temp);
      // set Title
      this.styleService.setTitle(temp);
      // set colors. small delay to make sure elements exists
      setTimeout(func => this.styleService.setColors(temp), 10)

    });
    // create form for login
    this.buildForm();
    // check backend status
    this.authenticationService.checkBackend().subscribe(
      status => {
        if (false === status) {
          // show "not response message"
          this.alertService.error('Palvelin ei vastaa');
        }
      },
      error => {
        // show "connection error message"
        this.alertService.error('Palvelimeen ei saada yhteyttä');
      }
    );
    // reset login status
    this.authenticationService.logout();
  }

  /**
   *
   *
   * @memberof LoginComponent
   */
  ngOnDestroy():void {
    this.sub.unsubscribe();
  }

  /**
   * Build the login form
   *
   * @memberof LoginComponent
   */
  buildForm():void {
    this.form = this.fb.group({
      username: ['', Validators.minLength(3)],
      password: ['', Validators.minLength(4)]
    });
    // FormGroup method for watching the changes. Keep checking and subscribe
    this.form.valueChanges.subscribe(data => {
      // validate login after one second delay. User has time to pass his credentials
      setTimeout((): void => {
        this.validateForm();
      }, 3000);
      // ...and do more for the form if needed
    });
  }

  /**
   * Do validation when user is typing inputs
   *
   * @memberof LoginComponent
   */
  validateForm():void {
    // tslint:disable-next-line:forin
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const input = this.form.get(field);
      if (input.invalid && input.dirty) {
        // tslint:disable-next-line:forin
        for (const error in input.errors) {
          this.formErrors[field] = this.validationMessages[this.language][field][error];
        }
      }
    }
  }

  /**
   * do login procedure
   *
   * @memberof LoginComponent
   */
  login():void {
    // get form values
    const val: Val = this.form.value;
    // show loading image during user waits on login
    this.spinner.show();
    // authentication service takes care of login procedure
    this.authenticationService.login(
      val.username, val.password).subscribe(data => {
        // check do we have login data available
        if (data) {
          // User is allowed to login. Navigate in right application 
          const gotourl: string = data['account'].Application.toLowerCase() ? data['account'].Application.toLowerCase() : '';
          
          if (data.user.passexp != "" && new Date() > new Date(data.user.passexp)){
            this.router.navigate([`/iforgetmypassword`, {m:"expired"}]);
            this.spinner.hide();
          } else {
            this.router.navigate([`/${gotourl}`]);
            this.spinner.hide();
          }
          
        } else {
          this.spinner.hide();
          this.alertService.error('Kirjautuminen epäonnistui. Tarkista käyttätunnuksesi ja salasanasi.');
        }
      },
      error => {
        // User failed to login
        this.alertService.error(error);
        this.spinner.hide();
      });
  }
}
