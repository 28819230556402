﻿import { InjectionToken, NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';

import { LoginComponent } from '@finesoft/core/components/login/login.component';
import { IforgetmypwComponent } from '@finesoft/core/components/iforgetmypw/iforgetmypw.component';
import { ChangepwComponent } from '@finesoft/core/components/changepw/changepw.component';
import { ChangepwLanderComponent } from '@finesoft/core/components/changepw-lander/changepw-lander.component';
import { AuthGuard } from '@finesoft/core/helpers/auth.guard';
import { AppComponent } from '@finesoft/app.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
const deactivateGuard = new InjectionToken('deactivateGuard');

const appRoutes: Routes = [
  // normal portal login
  { path: 'login', component: LoginComponent },
  // alternative paremeters for login
  { path: 'login/:styleId', component: LoginComponent },
  // forget password
  { path: 'iforgetmypassword', component: IforgetmypwComponent },
  // renew my password
  { path: 'renewpassword', component: ChangepwComponent },
  // lander page for parword renew if needed
  { path: 'renewpasswordlander', component: ChangepwLanderComponent},
  // application modules
  {
    path: '', component: AppComponent, canActivate: [AuthGuard],
    children: [
    {
      path: 'palveluportaali', loadChildren:
        '@finesoft/palveluportaali/palveluportaali.module#PalveluportaaliModule',
      canActivate: [AuthGuard]
    },
    {
      path: 'dispelix', loadChildren:
        '@finesoft/dispelix/dispelix.module#DispelixModule',
      canActivate: [AuthGuard] // not ready to be used
    },
    {
      path: 'insurance', loadChildren:
        '@finesoft/insurance/howden.module#HowdenModule',
      canActivate: [AuthGuard]
    },
    {
      path: 'ajo', loadChildren:
        '@finesoft/ajo/ajo.module#AjoModule',
      canActivate: [AuthGuard]
    },
    {
      path: 'nopest', loadChildren:
        '@finesoft/nopest/nopest.module#NopestModule',
      canActivate: [AuthGuard]
    },
    {
      path: 'siren', loadChildren:
        '@finesoft/siren/siren.module#SirenModule',
      canActivate: [AuthGuard]
    },
    {
      path: 'laakevakuutus', loadChildren:
        '@finesoft/laakevakuutus/laakevakuutus.module#LaakevakuutusModule',
      canActivate: [AuthGuard]
    },
    {
      path: 'mds', loadChildren:
        '@finesoft/mds/mds.module#MdsModule',
      canActivate: [AuthGuard]
    },]
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider,
    },
    // We need a component here because we cannot define the route otherwise
    component: LoginComponent,
  },
  // otherwise redirect to login
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {
        useHash: true // use HashLocationStrategy
      }
    )
  ],
  exports: [
    RouterModule
  ],
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalUrl = route.paramMap.get('externalUrl');
        window.open(externalUrl, '_self');
      },
    },
  ]
})

export class AppRoutingModule { }
