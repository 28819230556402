import { Component, OnInit } from '@angular/core';
import { ClockService } from './clock.service';

@Component({
  selector: 'app-clock',
  template: `<p>{{ time | date:'d.M.yyyy HH:mm' }}</p>`,
  styles: [``]
})
export class ClockComponent implements OnInit {
  time: Date;

  constructor(private clockService: ClockService) {
  }

  ngOnInit() {
    this.clockService.getClock().subscribe(time => {
      this.time = time;
    });
  }

}
