import { Injectable } from '@angular/core';
import { AuthenticationService } from '@finesoft/core/services/authentication.service';
import { NaviItem } from '@finesoft/core/models/navi-item';
import { NavItemNopest } from '@finesoft/nopest/shared/models/nav-item-nopest';

@Injectable()
export class NavigationService {
  constructor(private auth: AuthenticationService) { }

  getNavigationLinks(): NaviItem[] {
    const currentUser = JSON.parse(sessionStorage.getItem('loginUser'));
    const arr: NaviItem[] = [];
    currentUser.apps.forEach(element => {
        const item: NaviItem = {
            title: element.pageName,
            url: element.pageUrl
        };
        arr.push(item);
    });
    return arr;
  }
  getNavigationLinksNopest(): NavItemNopest[] {
    const currentUser = JSON.parse(sessionStorage.getItem('loginUser'));
    const arr: NavItemNopest[] = [];
    currentUser.apps.forEach(element => {
        const item: NavItemNopest = {
            title: element.pageName,
            url: element.pageUrl,
            files: 0
        };
        arr.push(item);
    });
    return arr;
  }
}
