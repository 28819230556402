﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, shareReplay, catchError } from 'rxjs/operators';
import { ConfigService } from '@finesoft/core/services/config.service';
import { Authentication } from '@finesoft/core/models/auth';
import { MfcommService } from '@finesoft/core/services/mfcomm.service';
import * as moment from 'moment';

@Injectable()
export class AuthenticationService {
  private authData: Authentication;   // authentication data
  private backendUrl: string;         // backend address from configuration file

  constructor(private configService: ConfigService,
    private mfcomms: MfcommService,
    private http: HttpClient) {
    //
    this.authData = null;
  }

  checkBackend(): Observable<any> {
    // get backend url address from configuration file
    this.backendUrl = this.configService.getConfiguration().MFBackendURL;
    return this.http.get<any>(this.backendUrl + '/ping');
  }

  public getToken(): string {
    const currentUser = sessionStorage.getItem('loginUser');
    if (!currentUser) { return currentUser; }
    const token = JSON.parse(currentUser);
    return token.token;
  }

  public getVault(): string {
    const currentUser = sessionStorage.getItem('loginUser');
    if (!currentUser) { return currentUser; }
    const token = JSON.parse(currentUser);
    return token.account.XVault;
  }

  public getServer(): string {
    const currentUser = sessionStorage.getItem('loginUser');
    if (!currentUser) { return currentUser; }
    const token = JSON.parse(currentUser);
    return token.account.Server;
  }

  /**
   * user authentication login
   * @param username
   * @param password
   */
  login(username: string, password: string) {
    return this.mfcomms.create(
        this.backendUrl,
        'auth/login',
        { username: username, password: password }
      ).pipe(
        tap(res => this.setSession(res)),
        shareReplay(),
        catchError(err => this.handleError(err)),
      );
  }

  /**
   * set session parameters from succesful login
   * @param authResult
   */
  private setSession(authResult) {
    this.authData = null;
    // get expires time
    const expiresAt = moment().add(authResult.expiresIn, 'second');
    // login successful if there's a token in the response
    if (authResult && authResult['user'] && authResult['token']) {
      // take parameters into the service
      this.authData = {
          user: authResult['user'],
          account: authResult['account'],
          apps: authResult['apps']
      };
      // store user details and jwt token in local storage to keep
      // user logged in between page refreshes
      sessionStorage.setItem(
        'loginUser',
        JSON.stringify(authResult));
      // set expires time
      sessionStorage.setItem(
        'expires_at',
        JSON.stringify(expiresAt));
    }
    return this.authData;
  }

  logout() {
    // remove user from local storage to log user out
    sessionStorage.removeItem('loginUser');
    // clear local data
    this.authData = null;
  }

  valid() {
    return this.authData !== null;
  }

  /**
   * Handles basic API errors
   * @param err
   */
  private handleError(err) {
    let errMessage: string;
    if (err instanceof Response) {
      const body = err.json() || '';
      // if body has an error OR stringify body (we don't know is it string already or it an object)
      const error = JSON.stringify(body);
      // if status exists, if statusText exist and error allow
      errMessage = `${err.status} - ${err.statusText || ''} ${error}`;
    } else {
      // if message comes through f rom server
      errMessage = err.message ? err.message : err.message.toString();
    }
    // return our error handler result
    return throwError('Kirjautumisvirhe');
  }
}
