import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MfcommService {
  constructor(
    private http: HttpClient,
    ) {}

  /**
   * Basic request
   * @param backend mf-backend address
   * @param endpoint query string
   */
  request(backend, endpoint): Observable<any> {
    return this.http.get(`${backend}/${endpoint}`);
  }

  /**
   * download a single file through mf-backend api
   */
  async downloadFile(backend, endpoint) {
    // wait until file is loaded

    const blob = await this.http.get(`${backend}/${endpoint}`, {responseType: 'blob'}).toPromise(); // has change blob type for @angular/common/http
      
    // check did we got a file
    if (blob) {
      return blob;
    } else {
      // unknown error happened
      console.log('MFCOMMS returned empty file: ', blob);
    }
    return null;
  }

  /**
   * getImage
   * Sets responseType: ResponseContentType Blob in GET-Request settings,
   *  because so you can get your image as blob and convert it later
   *  base64-encoded source.
   * @param backend
   * @param endpoint
   */
  getImage(backend, endpoint): Observable<Blob> {
    return this.http.get(`${backend}/${endpoint}`, { responseType: 'blob' });
  }

  /**
   * List items by query string
   */
  getList(backend, endpoint) {
    return this.http.get(`${backend}/api/${endpoint}/`).toPromise().then(
      response => {
        return response;
      });
  }

  /**
   * Query by specific id
   */
  getById(backend, endpoint, id: number) {
    return this.http.get(`${backend}/api/${endpoint}/${id}`).toPromise().then(
      response => {
        return response;
      });
  }

  /**
   * Post request for backend
   */
  create(backend, endpoint, data: any): Observable<any> {
    return this.http.post(`${backend}/${endpoint}/`, data);
  }
/*  create(backend, endpoint, data: any) {
    return this.http.post(`${backend}/${endpoint}/`, data).toPromise().then(
      response => {
        return response;
      });
  }*/

  /**
   * Put request for backend
   */
  update(backend, endpoint, data: any): Observable<any> {
    return this.http.put(`${backend}/api/${endpoint}/${data.id}`, data);
  }

  /**
   * Delete request for backend
   */
  delete(backend, endpoint, id: number): Observable<any> {
    return this.http.delete(`${backend}/api/${endpoint}/${id}`);
  }
}
