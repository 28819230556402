import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';

import { MfcommService } from '@finesoft/core/services/mfcomm.service';
import { StyleService } from '@finesoft/core/services/style.service';
import { UserService } from '@finesoft/core/services/user.service';
import { ClockService } from '@finesoft/core/components/clock/clock.service';

import { AlertComponent } from '@finesoft/core/components/alert/alert.component';
import { ClockComponent } from '@finesoft/core/components/clock/clock.component';
import { SafeHtmlPipe } from '@finesoft/core/helpers/safehtml-pipe';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    NgxSpinnerModule,
    RouterModule
  ],
  providers: [
    MfcommService,
    StyleService,
    UserService,
    ClockService
  ],
  declarations: [
    AlertComponent,
    ClockComponent,
    SafeHtmlPipe,
  ],
  exports: [
    AlertComponent,
    ClockComponent,
    NgxSpinnerModule,
    SafeHtmlPipe,
  ]
})
export class CoreModule { }
