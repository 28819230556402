import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Configuration } from '@finesoft/core/models/config-model';

@Injectable()
export class ConfigService {
  private config: Configuration;
  constructor(private http: HttpClient) { }

  /**
   * Loads a configuration. Used when portal is starting up.
   * @param url points to configuration file on portal environment
   */
  load(url: string) {
    return new Promise((resolve) => {
      this.http.get<Configuration>(url).subscribe(res => {
        this.config = res;
        resolve();
      });
    });
  }

  /**
   * Return loaded configuration
   */
  getConfiguration(): Configuration {
    return this.config;
  }
}
