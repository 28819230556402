import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthenticationService } from '@finesoft/core/services/authentication.service';
import { Observable } from 'rxjs';

// interceptor for attaching automatically authentication information to requests

@Injectable()
export class MfInterceptor implements HttpInterceptor {

  constructor(public auth: AuthenticationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // get current user from session storage
    const currentUser = JSON.parse(sessionStorage.getItem('loginUser'));
    if (currentUser && currentUser.token) {
      // clone original header and add X-Token authorization in it
      request = request.clone({
        setHeaders: {
          'X-Token': this.auth.getToken(),
          'X-Vault': this.auth.getVault(),
          'Server': this.auth.getServer()
        }
      });
    }
    // pass through
    return next.handle(request);
  }
}
