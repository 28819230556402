import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StyleService } from '@finesoft/core/services/style.service';


@Component({
  selector: 'app-changepw-lander',
  templateUrl: './changepw-lander.component.html',
  styleUrls: ['./changepw-lander.component.scss']
})
export class ChangepwLanderComponent implements OnInit {
  param: string;
  style: string;
  message: string;
  logoImage: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private styleService: StyleService){ 
    
    this.route.queryParams.subscribe(params => {
      this.param = params['t'];
      this.style = params['s'];
      this.styleService.setLoginBackground(this.style);
      this.logoImage = this.styleService.getLoginLogo(this.style);

      switch (this.style) {
        case "insurance":
          this.message = 'Tervetuloa Howden Finlandin portaaliin. Klikkaamalla tästä pääset luomaan itsellesi salasanan järjestelmään'
          break;

        default:
          break;
      }
      
      /*
      setTimeout(() => {
        this.router.navigate([`renewpassword`],{ queryParams: { t: this.param } });
      }, 500000000); // 5s
      */
    }); 
  }

  ngOnInit() {
  }

  routeToChangePassword() {
    this.router.navigate([`renewpassword`],{ queryParams: { t: this.param } });
  }

}
