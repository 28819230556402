import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@finesoft/core/services/config.service';

@Injectable()
export class StyleService {
  renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2, private config: ConfigService, private http: HttpClient) {
    // run RendererFactory2 to get Renderer2 instance inside this @Injectable() service
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * Load specific logo for login screen
   * @param styleId style by parameter
   */
  getLoginLogo(styleId: string): string {
    // if style id given...
    if ('' !== styleId) {
      // save style variable
      this.setStyle(styleId);
      // return image by style
      return `./assets/${styleId}/login-logo.png`;
    } else {
      // load default
      return `./assets/images/cloud-logo-login.png`;
    }
  }

  /**
   * set login screen background image and return path
   */
  setLoginBackground(styleId: string) {
    // if style id given...
    if ('' !== styleId) {
      let extension;
      switch (styleId) {
        case "dispelix" :
          extension = "png";
          break;
        default:
          extension = "jpg"
          break;
      }
      // save style variable
      this.setStyle(styleId);
      this.renderer.setStyle(document.body, 'background-image', `url(./assets/${styleId}/login-bg.${extension})`);
    } else {
      // load default (radomize)
      this.renderer.setStyle(document.body, 'background-image', 'url(' + this.getRandomBackgroundImage() + ')');
    }
  }

  /**
   * set background image
   */
  setBackgroundImage(imageUrl: String) {
    // if image url path is given
    if (imageUrl) {
      this.renderer.setStyle(document.body, 'background-image', `url(${imageUrl})`);
    } else {
      // load default (radomize)
      this.renderer.setStyle(document.body, 'background-image', 'url(' + this.getRandomBackgroundImage() + ')');
    }
  }

  /**
   * set background image
   */
  setBackgroundImageByStyle(styleId: string) {
    // if style id given...
    if (styleId) {
      // save style variable
      this.setStyle(styleId);
      this.renderer.setStyle(document.body, 'background-image', `url(./assets/${styleId}/bg.jpg)`);
    } else {
      // load default (radomize)
      this.renderer.setStyle(document.body, 'background-image', 'url(' + this.getRandomBackgroundImage() + ')');
    }
  }

  /**
   * get background image and return path
   */
  getBackgroundImageFromPath(imgPath: String): String {
    // if path id given...
    if ('' !== imgPath) {
      // TODO: check is image...
      return `imgPath`;
    } else {
      // load default (radomize)
      return this.getRandomBackgroundImage();
    }
  }

  /**
   * Randomize background image and return path
   * @param icon: boolean If true, return icon otherwise background image
   */
  private getRandomBackgroundImage(icon: boolean = false): String {
    const backgroundIndex = Math.round(Math.random() * 8);
    if (true === icon) {
      return `./assets/images/bg-${backgroundIndex}-icon.jpg`;
    }
    return `./assets/images/bg-${backgroundIndex}.jpg`;
  }

  /**
   *
   */
  public getCustomerLink(styleId: String): any {
    // if style id given...
    switch (styleId) {
      case 'insurance':
        return {
          title: 'www.howdenfinland.fi',
          link: 'http://www.howdenfinland.fi'
        };

      case 'nopest':
        return {
          title:'www.nopest.fi',
          link: 'http://www.nopest.fi'
        };
      
      case 'dispelix':
        return {
          title:'www.dispelix.com',
          link: 'http://www.dispelix.com'
        };

      case 'mds':
        return {
          title:'www.mdsfinland.fi',
          link: 'https://mdsfinland.fi/'
        };

      default:
        return ``;
    }
  }

  getLanguage(styleId: String): any {
    switch(styleId) {
      case 'dispelix':
      case 'mds':
        return "en";
    

      default:
        return "fi";
    }
  }

  getStyleID(style = ""):string {
    let hostname = window.location.hostname;
    if (hostname.includes('yritykset.howdenfinland')) style = 'insurance';
    if (hostname.includes('nopest.storageit')) style = 'nopest';
    if (hostname.includes('dispelix.storageit')) style = 'dispelix';
    if (hostname.includes('laakevakuutus.storageit')) style = 'laakevakuutus';
    if (hostname.includes('mds.storageit')) style = 'mds';
    return style;
  }

  /**
   * Sets style name on session storage
   * @param style name of current style
   */
  public setStyle(style: string) {
    // save style settings for session's use
    sessionStorage.setItem('style', style);
  }

  /**
   * Gets style name from session storage
   */
  public getStyle(): string {
    // save style settings for session's use
    return sessionStorage.getItem('style');
  }

 
  /**
   * Sets button color
   *
   * @param {string} style
   * @memberof StyleService
   */
  public setColors(style: string):void {
    let color: string = '#333'
    let elements = document.querySelectorAll(".iforgetmypassword");
    console.log(elements.length)

    
    switch (style) {
      case 'insurance':
        color = '#333'
        break;

      case 'nopest':
        color = '#91c370'
        break;

      case 'dispelix':
        color = '#080d1f';
        break;

      case 'laakevakuutus':
        color = '#6fbc3d';
        break;

      default:
        break;
    }

    for (let i = 0; i < elements.length; i++){
      elements[i].setAttribute('style', `background: ${color}`)
    }    
  }

  public setFavicon (style: string) {
    let favLink = 'favicon.ico';

    

    switch ( style ) {
      case 'insurance':
      favLink = './assets/insurance/Howden-hihamerkki-profiilikuva-digi-512x512.jpg';
      break;

      default:
      break;
    }

    if(favLink){

    }
    let favIcon = document.getElementById('favicon');
    favIcon.setAttribute('href', favLink);
  }

  public setTitle(style:string){
    let title = 'Palveluportaali'

    switch ( style ) {
      case 'nopest':
        title = 'Asiakasportaali';
      break;
    }

    document.title = title;
  }
}