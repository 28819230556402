import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { ConfigService } from '@finesoft/core/services/config.service';
import { MfcommService } from '@finesoft/core/services/mfcomm.service';
import { AlertService } from '@finesoft/core/services/alert.service';
import { PasswordValidation } from '@finesoft/core/components/changepw/password-validation';
import { ActivatedRoute } from '@angular/router';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { StyleService } from '@finesoft/core/services/style.service';

@Component({
  selector: 'app-changepw',
  templateUrl: './changepw.component.html',
  styleUrls: ['./changepw.component.scss']
})
export class ChangepwComponent implements OnInit {
  form: FormGroup;
  passwordsMatch: boolean;
  backendUrl: string;
  showLoginLink: boolean;
  showPasswordFields: boolean;
  style: string;
  validations = {
    vahinmaispituus: null,
    kirjaimet: null,
    isotkirjaimet: null,
    numerot: null,
    erikoismerkit: null,
    vanheneminen: null
  };
  private language : string;
  customMessage;
  logoImage: string;

  lang = {
    fi: {
      guide: "Käyttäjätilisi aktivoidaan vasta kun olet antanut henkilökohtaisen salasanan.",
      password:"Salasana",
      repeat:"Salasana uudestaan",
      guide2:"Luo salasana ja paina lähetä nappia.",
      send:"Lähetä",
      return:"Palaa kirjautumissivulle",
    },
    en: {
      guide: "Your account will be activated after you give your personalized password",
      password:"Password",
      repeat:"Repeat password",
      guide2:"Create password and press send",
      send:"Send",
      return:"Return to login page",
      req: {}
    },
  }

  // types of validateros
  formErrors = {
    password: '',
    confirmPassword: ''
  };

  // TODO: get validation messages from language file
  public validationMessages = {
    fi: {
      password: {
        minlength: `Salasanan täytyy olla vähintään X merkkiä pitkä`,
        minletters: `Salasanassa täytyy olla X kirjainta`,
        mincapitals: `Salasanassa täytyy olla X isoakirjainta`,
        minnumbers: `Salasanassa täytyy olla X numeroa`,
        minspecials: `Salasanassa täytyy olla X erikoismerkkiä`
      },
      confirmPassword: {
        minlength: 'Salasanan täytyy olla vähintään viisi merkkiä pitkä',
        MatchPassword: 'Salasanojen täytyy vastata toisiaan'
      }
    },
    en: {
      password: {
        minlength: `Password must be atleast x characters long`,
        minletters: `Password must have at least x letters`,
        mincapitals: `Password must have at least x capital letters`,
        minnumbers: `Password must have at least x numbers`,
        minspecials: `Password must have at least x special characters`
      },
      confirmPassword: {
        minlength: `Password must be atleast x characters long`,
        MatchPassword: 'Passwords must match eachother'
      }
    }

    
  };

  constructor(private fb: FormBuilder,
    private configService: ConfigService,
    private mfcomm: MfcommService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private styleService: StyleService) { }

  ngOnInit() {
    this.showLoginLink = false;
    this.showPasswordFields = true;
    // get backend url address from configuration file
    this.backendUrl = this.configService.getConfiguration().MFBackendURL;
    
    // get styleID
    let style = this.styleService.getStyle()
    if(!style){
      style = this.styleService.getStyleID()
      this.styleService.setStyle(style);
    }
    this.style = style
    // get language
    this.language = this.styleService.getLanguage(style);
    // set background image
    this.styleService.setLoginBackground(style);
    this.logoImage = this.styleService.getLoginLogo(style)
    

    // create form for login
    this.getValidations();
  }

    /**
   * build reactive form
   */
  buildForm() {
    this.passwordsMatch = false;
    this.form = this.fb.group({
      password: ['', [
        Validators.minLength(this.validations.vahinmaispituus), 
        Validators.required, 
        this.minLetters( parseInt(this.validations.kirjaimet) ), 
        this.minCapitals( parseInt(this.validations.isotkirjaimet) ),
        this.minNumbers( parseInt(this.validations.numerot) ), 
        this.minSpecial( parseInt(this.validations.erikoismerkit) )]],
      confirmPassword: ['', Validators.required],
    },
    {
      validator: PasswordValidation.MatchPassword
    });
    // FormGroup method for watching the changes. Keep checking and subscribe
    this.form.valueChanges.subscribe(data => {
      // validate login after one second delay. User has time to pass his credentials
      setTimeout((): void => {
        this.validateForm();
      }, 3000);
      // ...and do more for the form if needed
      this.passwordsMatch = (this.form.valid && this.form.dirty);
    });
  }

  minLetters(amount):ValidatorFn{
    const numtest = /[a-z]/gi
    return (control: AbstractControl): {[key: string]: any} | null => {
      console.log(control.value)
      //const result = numtest.test(control.value);
      const pass = control.value as string
      const result: Array<any> = pass.match(numtest)
      if (!result) return null;
      console.log(result, "letters")
      return result.length >= amount ? null : {minletters: {value: control.value}};
    }
  }

  minCapitals(amount):ValidatorFn{
    const numtest = /[A-Z]/g
    return (control: AbstractControl): {[key: string]: any} | null => {
      console.log(control.value)
      //const result = numtest.test(control.value);
      const pass = control.value as string
      const result: Array<any> = pass.match(numtest)
      if (!result) return null;
      console.log(result, "letters")
      return result.length >= amount ? null : {mincapitals: {value: control.value}};
    }
  }

  minNumbers(amount):ValidatorFn{
    const numtest = /[\d]/gi
    return (control: AbstractControl): {[key: string]: any} | null => {
      //const result = numtest.test(control.value);
      const pass = control.value as string
      const result: Array<any> = pass.match(numtest)
      if (!result) return null;
      console.log(result, "numbers")
      return result.length >= amount ? null : {minnumbers: {value: control.value}};
    }
  }

  minSpecial(amount):ValidatorFn{
    const numtest = /[^\d,^a-z]/gi
    return (control: AbstractControl): {[key: string]: any} | null => {
      //const result = numtest.test(control.value);
      const pass = control.value as string
      const result: Array<any> = pass.match(numtest)
      if (!result) return null;
      console.log(result, "specials")
      return result.length >= amount ? null : {minspecials: {value: control.value}};
    }
  }

  

  /**
   * Do validation when user is typing inputs
   */
  validateForm() {
    // tslint:disable-next-line:forin
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const input = this.form.get(field);
      if (input.invalid && input.dirty) {
        // tslint:disable-next-line:forin
        for (const error in input.errors) {
          console.log(field, error)
          this.formErrors[field] = this.validationMessages[this.language][field][error];
        }
      }
    }
  }

  /**
   *
   */
  doChange() {
    this.route.queryParams.subscribe(params => {
      const token = params['t'];
      // send email via post request to backend
      this.mfcomm.create(this.backendUrl, `api/auth/signin/${token}`, { password: this.form.value.password, expiration: this.validations.vanheneminen }).subscribe(
        response => {
          // tslint:disable-next-line:max-line-length
          switch (this.styleService.getStyle()) {
            case "dispelix":
                this.alertService.success('Your account has been activated. You can now login in to the system.');
            break;
            default:
                this.alertService.success('Käyttäjätunnuksesi on nyt aktivoitu. Voit kirjautua järjestelmään kirjautumissivulta.');
            break;
          }
          this.showPasswordFields = false;
          this.showLoginLink = true;
        },
        error => {
          this.alertService.error('Käyttäjätunnustasi ei voida rekisteröidä. Linkkisi voi olla vanhentunut.');
        });

    });
  }

  getValidations() {
    this.route.queryParams.subscribe(params => {
      const token = params['t'];

      this.mfcomm.request(this.backendUrl, `api/auth/valid/${token}`).subscribe(
        response => {
          console.log(response)
          this.validations = response;
          this.buildForm();
          this.updateValidationMessages();
        },
        error => {
          console.log(error)
        });
    })
  }

  updateValidationMessages(){
    switch (this.styleService.getStyle()) {
      case "dispelix":
        this.customMessage = 
        `Your password must at least contain: <br>
        - 8 characters <br>
        - 1 letter <br>
        - 1 capital letter <br>
        - 1 number <br>
        - 1 special character <br> 
        <br>`
      break;
    }
    
    this.validationMessages = {
      fi: {
        password: {
          minlength: `Salasanan täytyy olla vähintään ${ this.validations.vahinmaispituus } merkkiä pitkä`,
          minletters: `Salasanassa täytyy olla ${ this.validations.kirjaimet } ${this.validations.kirjaimet > 1 ? 'kirjainta' : 'kirjain'}`,
          mincapitals: `Salasanassa täytyy olla ${ this.validations.isotkirjaimet } ${ this.validations.isotkirjaimet > 1 ? 'isoakirjainta' : 'isoakirjain' }`,
          minnumbers: `Salasanassa täytyy olla ${ this.validations.numerot } ${this.validations.numerot > 1 ? 'numeroa' : 'numero'}`,
          minspecials: `Salasanassa täytyy olla ${ this.validations.erikoismerkit } ${ this.validations.erikoismerkit > 1 ? 'erikoismerkkiä' : 'erikoismerkki' }`
        },
        confirmPassword: {
          minlength: `Salasanan täytyy olla vähintään ${ this.validations.vahinmaispituus } merkkiä pitkä`,
          MatchPassword: 'Salasanojen täytyy vastata toisiaan'
        }
      },
      
      en: {
        password: {
          minlength: `Password must be atleast ${ this.validations.vahinmaispituus } characters long`,
          minletters: `Password must have at least ${ this.validations.kirjaimet } ${this.validations.kirjaimet > 1 ? 'letters' : 'letter'}`,
          mincapitals: `Password must have at least ${ this.validations.isotkirjaimet } ${ this.validations.isotkirjaimet > 1 ? 'capital letters' : 'capital letter' }`,
          minnumbers: `Password must have at least ${ this.validations.numerot } ${this.validations.numerot > 1 ? 'numbers' : 'number'}`,
          minspecials: `Password must have at least ${ this.validations.erikoismerkit } ${ this.validations.erikoismerkit > 1 ? 'special characters' : 'special character' }`
        },
        confirmPassword: {
          minlength: `Password must be atleast ${ this.validations.vahinmaispituus } characters long`,
          MatchPassword: 'Passwords must match eachother'
        }
      }
    };
  }

}
