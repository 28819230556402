﻿import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@finesoft/core/services/user.service';
import { StyleService } from '@finesoft/core/services/style.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private user: UserService,
    private style: StyleService,
    private router: Router) { }

  /**
   * check are we ready to activate route
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let status = false;
    // is user logged in
    if (this.user.isUserLoggedIn()) {
      // logged in
      status = true;
    }
    // check right application
    if ('' !== next.routeConfig.path && next.routeConfig.path !== this.user.getAccount().Application) {
      status = status && false;
    }
    // if all checks are passed
    if (status) {
      return true;
    }
    // not logged in so redirect to login page with the return url
    const style = this.style.getStyle() ? this.style.getStyle() : '';
    this.router.navigate([`/login/${style}`]);
    return false;
  }
}
