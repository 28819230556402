﻿import { Component, OnInit } from '@angular/core';

import { AlertService } from '@finesoft/core/services';

@Component({
  moduleId: module.id.toString(),
  selector: 'app-alert',
  templateUrl: './alert.component.html'
})

export class AlertComponent implements OnInit {
  message: string;
  constructor(private alertService: AlertService) { }
  ngOnInit() {
    this.alertService.getMessage().subscribe(message => { this.message = message; });
  }
}
