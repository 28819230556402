﻿import { APP_INITIALIZER } from '@angular/core';
import { ConfigService } from '@finesoft/core/services/config.service';
// Load configurations here in the module!
import { environment } from '../environments/environment';
export function ConfigLoader(configService: ConfigService) {
  // Note: this factory need to return a function (that return a promise)
  return () => configService.load(environment.configFile);
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from '@finesoft/app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppRoutingModule } from '@finesoft/app.routing-module';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AuthGuard } from '@finesoft/core/helpers/auth.guard';
import { MfInterceptor } from '@finesoft/core/helpers/mf.interceptor';
import { ExternalUrlDirective } from '@finesoft/core/helpers/external-url.directive';
import { AlertService, AuthenticationService, NavigationService } from '@finesoft/core/services/index';

import { LoginComponent } from '@finesoft/core/components/login/login.component';
import { IforgetmypwComponent } from '@finesoft/core/components/iforgetmypw/iforgetmypw.component';
import { ChangepwComponent } from '@finesoft/core/components/changepw/changepw.component';

import { CoreModule } from '@finesoft/core/core.module';

import { ChangepwLanderComponent } from '@finesoft/core/components/changepw-lander/changepw-lander.component';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    NgbModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    IforgetmypwComponent,
    ChangepwComponent,
    ExternalUrlDirective,
    ChangepwLanderComponent,
  ],
  providers: [
    ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps: [ConfigService],
      multi: true
    },
    AuthGuard,
    AlertService,
    NavigationService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MfInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
