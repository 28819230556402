import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ConfigService } from '@finesoft/core/services/config.service';
import { MfcommService } from '@finesoft/core/services/mfcomm.service';
import { AlertService } from '@finesoft/core/services/alert.service';
import { StyleService } from '@finesoft/core/services/style.service';

import { equalValueValidator } from '@finesoft/core/components/iforgetmypw/equalvaluevalidator';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-iforgetmypw',
  templateUrl: './iforgetmypw.component.html',
  styleUrls: ['./iforgetmypw.component.scss']
})
export class IforgetmypwComponent implements OnInit {
  form: FormGroup;
  backendUrl: string;
  captcha: any;
  tagtext: string;  // hidden field
  private sub: any; // alternative login features via url parameters
  language: string = "fi";
  guide = "guide"

  lang = {
    fi: {
      guide: "Anna käyttäjätiliisi liitetty sähköpostiosoite, niin toimitamme ohjeet salasanasi palauttamiselle.",
      expired: "Salasanasi on vanhentunut, Anna käyttäjätiliisi liitetty sähköpostiosoite luodaksesi uuden salasanan",
      email: "Sähköpostiosoite",
      tag: "Kirjoita ylläoleva tunniste tähän",
      order: "Tilaa salasana",
      return: "Palaa kirjautumissivulle",
      sended: "Salasana tilattu. Tarkista sähköpostisi.",
      noSend: "Salasanan tilauksessa tapahtui virhe. "
    },
    en: {
      guide: "Provide your accounts e-mail and we will send instruction on how to recover your password.",
      expired: "Yuor password has expired, provide your accounts e-mail to order new password",
      email: "E-mail adress",
      tag: "Write above tag here",
      order: "Order password",
      return: "Return to login page",
      sended: "Your password has been ordered. Check your email.",
      noSend: "Your password was not ordered due to error. "
    },
  }

  // types of validateros
  formErrors = {
    email: '',
    tag: ''
  };

  // TODO: get validation messages from language file
  validationMessages = {
    fi:{
      email: {
        required: 'Sähköposti vaaditaan',
        email: 'Sähköpostiosoitteen täytyy olla oikeassa muodossa.'
      },
      tag: {
        required: 'Tunniste vaaditaan'
      }
    },
    en:{
      email: {
        required: 'E-mail is required',
        email: 'E-mail must be in correct form'
      },
      tag: {
        required: 'Tag is required'
      }
    },
  };

  constructor(private fb: FormBuilder,
    private configService: ConfigService,
    private mfcomm: MfcommService,
    private alertService: AlertService,
    private styleService: StyleService,
    private route: ActivatedRoute,) { 
    }

  ngOnInit() {
    this.route.params.subscribe(params => {
      console.log(params)
      this.guide = params['m']
    })
    let style = this.styleService.getStyle();

    if(!this.styleService.getStyle()){
      style = this.styleService.getStyleID();
      this.styleService.setStyle(style);
    }

    this.styleService.setLoginBackground(style);
    this.language = this.styleService.getLanguage(style);
    // get backend url address from configuration file
    this.backendUrl = this.configService.getConfiguration().MFBackendURL;
    // create form for login
    this.getCaptcha();
    this.buildForm();
    // get captcha
  }

  /**
   * build reactive form
   */
  buildForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      tag: ['', [Validators.required]],
      tagtext: ['']
    },
      {
        validator: equalValueValidator('tag', 'tagtext')
      }
    );
    // FormGroup method for watching the changes. Keep checking and subscribe
    this.form.valueChanges.subscribe(data => {
      // validate login after one second delay. User has time to pass his credentials
      setTimeout((): void => {
        this.validateForm();
      }, 3000);
      // ...and do more for the form if needed
    });
  }

  /**
   * Do validation when user is typing inputs
   */
  validateForm() {
    // tslint:disable-next-line:forin
    for (const field in this.formErrors) {
      this.formErrors[field] = '';
      const input = this.form.get(field);
      if (input.invalid && input.dirty) {
        // tslint:disable-next-line:forin
        for (const error in input.errors) {
          console.log(field);
          this.formErrors[field] = this.validationMessages[this.language][field][error];
        }
      }
    }
  }

  getCaptcha() {
    this.mfcomm.request(this.backendUrl, 'api/captcha').subscribe(
      response => {
        this.captcha = response;

        this.form.controls['tagtext'].setValue(response.text);
      },
      err => {
        console.log(err);
      });
  }

  /**
   *
   */
  orderPw() {
    // send email via post request to backend
    this.mfcomm.create(this.backendUrl, `api/auth/orderpw`, { email: this.form.value.email }).subscribe(
      response => {
        this.alertService.success(this.lang[this.language]['sended']);
      },
      error => {
        console.log(error);
        let msg = error.error.error;
        this.alertService.error(this.lang[this.language]['noSend'] + msg);
      });
  }
}
