import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { interval } from 'rxjs/observable/interval';

@Injectable()
export class ClockService {
  constructor() { }

  getClock(): Observable<Date> {
    return new Observable(
      observer => {
      interval(1000).subscribe(() => observer.next(new Date()));
    });
  }
}
