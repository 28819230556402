﻿import { Component, Renderer2 } from '@angular/core';
import { StyleService } from '@finesoft/core/services/style.service';
// for watching are we in development mode
import { environment } from '../environments/environment';

@Component({
    moduleId: module.id.toString(),
    selector: 'app-data',
    template: `<div id="outer">
      <router-outlet></router-outlet>
      </div>
    `
})

export class AppComponent {
  // check mode form project environment
  production: boolean = environment.production;
  //
  constructor(private renderer: Renderer2, private style: StyleService) {
    // Pass Renderer2 from component to service
    style.renderer = renderer;
  }
}
