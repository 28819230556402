﻿import { Injectable } from '@angular/core';
import { LoginUser } from '../models/loginuser';


@Injectable()
export class UserService {

  constructor() { }

  /**
   * Helper function for get user's login status
   */
  public isUserLoggedIn(): boolean {
    // check do we have user credentials
    if (this.getLoginUser()) {
      // user logged in
      return true;
    }
    // user not logged in
    return false;
  }

  /**
   * Get user's info from session storage
   */
  public getLoginUser(): any {
    const currentUser: LoginUser = JSON.parse(sessionStorage.getItem('loginUser'));
    if(!currentUser) {
      return "";
    }
    return currentUser.user;
  }

  /**
   * Get account info from session storage
   */
  public getAccount(): any {
    const temp: LoginUser = JSON.parse(sessionStorage.getItem('loginUser'));
    if(!temp) {
      return "";
    }
    return temp.account;
  }
}
